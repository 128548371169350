/* Footer */

.Footer {
    background-color: #292426;
    height: 400px;
    /* margin-top: 100px; */
    color: white;
}

.QucikDetails {
    display: flex;
    align-items: start;
    justify-content: space-around;
    padding: 10px;
    gap: 10px;
}

.ListQuickDetails {
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.LeftQuick {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.LeftQuick h3{
    color: #ff8031;
}

.RightQuick {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.RightQuick h3{
    color: #ff8031;
}



.addresBox {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 250px;
    font-size: 1rem;
}

.icon {
    scale: 1.6;
    color: #ff8031;
}

input {
    padding: 10px;
    margin: 5px 0;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 200px;
}

/* Styles for Hover and Focus States */
input:hover,
input:focus {
    border-color: #005abb;
    /* Change color on hover and focus */
    outline: none;
    /* Remove default focus outline */
}

/* Styles for Disabled State */
input:disabled {
    background-color: #f0f0f0;
    /* Change background color for disabled state */
}

.line {
    width: 100%;
    height: 30px;
    color: #ff8031;
}

/* FooterSec */

.Footer {
    padding: 20px;
}

.FooterSec {
    display: flex;
    justify-content: space-around;
    padding: 5px 20px;
}
.logo h1{
    color: #ff8031;
}

.logoSecFooter {
    display: flex;
    flex: end;
    justify-content: start;
    flex-direction: column;
    width: 50vw;
}

.serviceFooter {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;

}

.LogoDisFooter{
    width: 40vw;
}

.LogoDisFooterP{
    width: 40vw;
    color: #b27c5b;
}
.linksFooter {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;


}
.linksFooter .FooterLink{
    cursor: pointer;
    color: white;
    text-decoration: none;
}


.linksFooter span:hover {
    color: #ff8031;
    
}
.linksFooter h2{
    color: #ff8031;
}

.inputSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.sendEmailFooter {
    background-color: #ff8031;
    padding: 11px;
    border-radius: .7rem;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
}

.sendEmailFooter:hover {
    transform: scale(1.2);
}


@media screen and (min-width: 280px) and (max-width: 800px) {
    .logo h1{
        font-size: 1.5rem;
    }

    .linksFooter,.serviceFooter{
        width: 70vw;
    }
    .Footer{
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .FooterSec{
        flex-direction: column;
        height: auto;
    }


    .LogoDisFooter{
        width: 80vw;
    }
    .QucikDetails{
        display: flex;
        flex-direction: column-reverse;
    }
    .ListQuickDetails{
        flex-direction: column;
        gap: 20px;
    }
    .QucikDetails{
        flex-direction: column;
    }

    .logoSecFooter {
        width: 80vw;
    }

}

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .ListQuickDetails{
        flex-direction: column;
        gap: 30px;
    }
    .FooterSec{
        padding: 0;
    }

}

.copyRightSectionFooter{
    background-color: #231e20;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyRightSectionFooter h4{
    padding: 0px;
    color: white;
    font-weight: 400;
    text-align: center;
}
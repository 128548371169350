.AboutBanner {
    background-image:linear-gradient(#27110581,#32140349) ,url(../assets/inso\ \(3\).jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutBanner h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 4rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


.parallerEffect {
    background-image: url(../assets/para\ \(2\).jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.parallerEffect h1 {
    font-size: 5rem;
    color: white;
}

.aboutTag {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.aboutTag span {
    padding: 10px;
    background-color: #8c5c3f;
    border-left: 5px solid rgb(255, 106, 0);
    color: white;
}

.contactAbout {
    padding: 20px 180px;
    /* background-image: url(../assets/bg\ element.jpg); */
}

.contactAbout p {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
}

.ClassUl {

    list-style-type: none;
    padding: 20px;
    font-size: 2rem;
}

.ClassUl .liClass {
    text-align: center;
    background-color: #ffffff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 1rem;
    color: #002244;
    padding: 15px;
    margin-bottom: 8px;
    font-size: 1.3rem;

}

.ClassUl .liClass:hover {
    box-shadow: rgba(235, 147, 65, 0.441) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.aboutTextList {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #cf6320;
}

.aboutPart1 {
    width: 500px;
}

.aboutPart2 {
    width: 300px;
}

.aboutPart2 h3 {
    font-size: 2rem;
}

@media screen and (min-width: 270px) and (max-width: 800px) {
    .AboutBanner h1 {
        color: #ffffff;
   
        font-size: 2rem;
     
    }

    .contactAbout {
        padding: 10px;
    }

   

    .aboutTextList {
        flex-direction: column;
        padding: 10px;
    }

    .aboutPart1 {
        width: 96vw;
    }

    .aboutPart2 {
        width: 96vw;
    }

    .parallerEffect h1 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .contactAbout {
        padding: 10px;
    }

    .AboutBanner h1 {
        color: #ffffff;
        font-size: 3rem;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }

    .aboutTextList {
        flex-direction: column;
        padding: 10px;
    }

    .aboutPart1 {
        width: 96vw;
    }

    .aboutPart2 {
        width: 96vw;
    }
}
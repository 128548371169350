@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Jost:wght@700&display=swap');

* {
    font-family: "DM Sans", sans-serif;
}

.servicesection {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.68)), url(../assets/unsplashService.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.serviceText {
    font-size: 100px;
    color: azure;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;;
    /* text-shadow: 3px 4px 6px rgba(0, 0, 0, 0.47); */


}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .servicesection{
        height: 50vh;
        width: 100vw;
    }

    .serviceText{
        font-size: 80px;
    }
}

@media screen and (min-width: 280px) and (max-width: 600px){
    .servicesection{
        height: 35vh;
        width: 100vw;
    }
    .serviceText{
        font-size: 40px;
    }
}

.cardSection {
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(#cee6f5ce, #aadafa96), url(../assets/bgService3.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    align-content: center; 
    justify-items: center; 
    gap: 20px;
    padding: 30px;
}

.singleCard {
    background-color: rgb(255, 255, 255);
    height: 56vh;
    width: 22vw;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 3px 32px -12px rgba(0,0,0,0.41);
}

.cardIcon {
    background-color: rgb(255, 255, 255);
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    top: -10rem;
    left: 13.5rem;
    border-radius: 0.5rem;
    box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.53);
    z-index: 5;
}

.cardimage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.34));
    height: 28vh;
    width: 22vw;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    object-fit: cover;
}

.cardText {
    margin: 2.5rem 1rem 0 1rem;

    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.cardLargeText {
    color: rgb(59, 59, 233);
    font-weight: 700;
    font-size: 1.2rem;
}

@media screen and (min-width: 600px) and (max-width: 1000px){

    .cardSection {
        background-color: rgb(255, 255, 255);
        background-image: linear-gradient(#cee6f5ce, #aadafa96), url(../assets/bgService3.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        display: grid;
        /* grid-template-columns: repeat(2, 1fr);  */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-content: center; 
        justify-items: center; 
        gap: 20px;
        padding: 30px;
    }

    .singleCard {
        background-color: rgb(255, 255, 255);
        /* display: flex; */
        /* justify-content: center;
        align-items: center; */
        /* flex-direction: column; */
        height: 350px;
        width: 250px;
        margin: 1rem;
        border-radius: 1rem;
        box-shadow: 0px 3px 32px -12px rgba(0,0,0,0.41);
    }

    .cardimage {
        background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.34));
        height: 175px;
        width: 250px;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        object-fit: cover;
    }

    .cardIcon {
        background-color: rgb(255, 255, 255);
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        top: -180px;
        left: 170px;
        border-radius: 0.5rem;
        box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.53);
        z-index: 5;
    }
}

@media screen and (min-width: 281px) and (max-width: 600px){

    .cardSection {
        background-color: rgb(255, 255, 255);
        background-image: linear-gradient(#cee6f5ce, #aadafa96), url(../assets/bgService3.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        display: grid;
        /* grid-template-columns: repeat(2, 1fr);  */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-content: center; 
        justify-items: center; 
        gap: 20px;
        padding: 30px;
    }

    .singleCard {
        background-color: rgb(255, 255, 255);
        /* display: flex; */
        /* justify-content: center;
        align-items: center; */
        /* flex-direction: column; */
        height: 350px;
        width: 250px;
        margin: 1rem;
        border-radius: 1rem;
        box-shadow: 0px 3px 32px -12px rgba(0,0,0,0.41);
    }

    .cardimage {
        background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.34));
        height: 175px;
        width: 250px;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        object-fit: cover;
    }
    .cardIcon {
        background-color: rgb(255, 255, 255);
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        top: -180px;
        left: 170px;
        border-radius: 0.5rem;
        box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.53);
        z-index: 5;
    }
}

@media screen and (min-width: 200px) and (max-width: 280px){
    .cardSection {
        background-color: rgb(255, 255, 255);
        background-image: linear-gradient(#cee6f5ce, #aadafa96), url(../assets/bgService3.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        display: grid;
        /* grid-template-columns: repeat(2, 1fr);  */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-content: center; 
        justify-items: center; 
        /* gap: 20px; */
        padding: 0;
    }

    .singleCard {
        background-color: rgb(255, 255, 255);
        /* display: flex; */
        /* justify-content: center;
        align-items: center; */
        /* flex-direction: column; */
        height: 350px;
        width: 250px;
        margin: 10px 20px 10px 0;
        border-radius: 1rem;
        box-shadow: 0px 3px 32px -12px rgba(0,0,0,0.41);
    }

    .cardimage {
        background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.34));
        height: 175px;
        width: 250px;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        object-fit: cover;
    }
    .cardIcon {
        background-color: rgb(255, 255, 255);
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        top: -180px;
        left: 170px;
        border-radius: 0.5rem;
        box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.53);
        z-index: 5;
    }
}

.ServiceBottomSection{
    display: flex;
    background-color: rgb(255, 255, 255);
}

.TalkToAgent {
    height: 70vh;
    width: 30vw;
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    position: sticky;
    top: 4rem;
}

.agentImage {
    margin: 50px 0 1rem 4rem;
    height: 22rem;
    width: 22rem;
    background-image: linear-gradient(rgba(0, 4, 6, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/pexels1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1rem;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.serviceIcon {
    background-color: #362f32;
    width: 5rem;
    height: 5rem;
    position: relative;
    top: 1rem;
    left: 43%;
    border-radius: 4rem;
    box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.53);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 0 0;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {

    .ServiceBottomSection{
        display: block;
    }

    .TalkToAgent {
        height: 400px;
        width: 100vw;
        background-color: rgb(255, 255, 255);
        display: flex;
        position: static;
        top: 4rem;
        justify-content: center;
        align-items: center;
    }

    .agentImage {
        margin: 0;
        height: 22rem;
        width: 22rem;
        background-image: linear-gradient(rgba(0, 4, 6, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/pexels1.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 1rem;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
}

@media screen and (min-width: 270px) and (max-width: 600px) {
    .ServiceBottomSection{
        display: block;
    }

    .TalkToAgent {
        height: 450px;
        width: 95vw;
        background-color: rgb(255, 255, 255);
        display: flex;
        position: static;
        top: 4rem;
        justify-content: center;
        align-items: center;
        margin: 0 2vw 0 2vw;
    }

    .agentImage {
        margin: 0;
        height: 22rem;
        width: 22rem;
        background-image: linear-gradient(rgba(0, 4, 6, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/pexels1.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 1rem;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

}

@media screen and (min-width: 200px) and (max-width: 280px){

}

.mainFeatures {
    /* background-color: bisque; */
    background-color: rgb(255, 255, 255);
    /* display: inline-flex; */
    width: 65vw;
    height: 170vh;
    margin-left: 3vw;
}

.searviceFearture{
    margin-top: 3rem;
    margin-bottom: 40px;
    display: inline-flex;
}

.featureImage {
    display: inline-flex;
    /* background-color: green; */
    /* height:65vh;
    width: 30vw; */
    height:22rem;
    width: 22rem;
    position: relative;
    box-sizing: border-box;
    direction: ltr;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-size-adjust: 100%;
    border-radius: 20px;
}

.insurancedetailsText {
    position: absolute;
    bottom: 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80px;
    left: 0px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 18px;
    right: 0px;
    width: 22rem;
    direction: ltr;
    font-family: "DM Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 26px;
    text-size-adjust: 100%;
    color: rgb(255, 255, 255);
    background-image: -webkit-linear-gradient(180deg, rgb(12, 224, 255) 0%, rgb(1, 95, 201) 100%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.featureText {
    height: 65vh;
    width: 32vw;
    text-align: start;
    margin-left: 2rem;
}

.featureTextheading{
    font-size: 2rem;
    font-weight: 600;
    /* margin: 1rem; */
    margin-left: 1rem;
}

.featureTextheading2{
    margin: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {

    .mainFeatures {
        /* background-color: bisque; */
        background-color: rgb(255, 255, 255);
        /* display: inline-flex; */
        width: 94vw;
        height: 77vh;
        /* height: 170vh; */
        padding: 0 3vw 3vw 3vw;
        margin: 0;
    }

    .searviceFearture{
        /* margin-top: 3rem;
        margin-bottom: 40px; */
        display: flex;
        /* background-color: aquamarine; */
    }

    .featureText {
        /* display: inline-flex; */
        background-color: rgb(255, 255, 255);
        height: auto;
        width: 42vw;
        text-align: start;
        margin-left: 2rem;
    }
}

@media screen and (min-width: 280px) and (max-width: 600px) {
    .mainFeatures {
        /* background-color: bisque; */
        background-color: rgb(255, 255, 255);
        /* display: inline-flex; */
        width: 94vw;
        height: 136vh;
        /* height: 170vh; */
        padding:  0 3vw 3vw 3vw;
        margin: 0;
    }

    .searviceFearture{
        margin-top: 0;
        /* margin-bottom: 40px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-color: aquamarine; */
    }

    .featureImage {
        display: flex;
        
        height:22rem;
        width: 22rem;
        position: relative;
        box-sizing: border-box;
        direction: ltr;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-size-adjust: 100%;
        border-radius: 20px;
    }

    .featureText {
        /* display: inline-flex; */
        background-color: rgb(255, 255, 255);
        height: auto;
        width: 350px;
        text-align: start;
        margin-left: 0;
        margin-top: 30px;
    }
}

@media screen and (min-width: 200px) and (max-width: 280px){
    .mainFeatures {
        /* background-color: bisque; */
        background-color: rgb(255, 255, 255);
        /* display: inline-flex; */
        width: 94vw;
        height: 190vh;
        /* height: 170vh; */
        padding:  0 3vw 3vw 3vw;
        margin: 0;
    }

    .searviceFearture{
        margin-top: 0;
        /* margin-bottom: 40px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-color: aquamarine; */
    }

    .featureImage {
        display: flex;
        
        height:95vw;
        width: 95vw;
        position: relative;
        box-sizing: border-box;
        direction: ltr;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-size-adjust: 100%;
        border-radius: 20px;
    }

    .insurancedetailsText {
        width: 95vw;
    }

    .featureText {
        /* display: inline-flex; */
        background-color: rgb(255, 255, 255);
        height: auto;
        width: 100vw;
        text-align: start;
        margin-left: 0;
        margin-top: 30px;
    }
}


.qualityCard1{
    display: inline-flex;
    background-color: rgb(247, 247, 247);
    width:60vw;
    border-radius: 10px;
    /* box-shadow: 0px 2px 81px -24px rgba(0,0,0,0.7);  */
    box-shadow: 0px 2px 23px 4px rgba(0,0,0,0.18);
    margin: 0.8rem 0 0 0;
}

.qualityIcon{
    margin: 2.5rem;
}

.qualityText{
    font-size: 1.5rem;
    font-weight: 700;
    /* font-size: 400; */
    margin: 2.5rem 2rem 2rem 1rem;
    width: 20rem;  
    display: flex;
    justify-content: center;
    align-items: center;
}

.qualityLine{
    width: 1px;
    background-color: rgb(163, 161, 161);
    height: 5rem;
    margin: 2rem 2rem 2rem 1rem;
}

.qualityPara{
     text-justify: inter-word;
     display: flex;
     align-items: center;
     color: gray;
}


.qualityCard1:hover{
    background-color: #bde3fc96;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .qualityCard1{
        display: inline-flex;
        background-color: rgb(247, 247, 247);
        width:94vw;
        border-radius: 10px;
        /* box-shadow: 0px 2px 81px -24px rgba(0,0,0,0.7);  */
        box-shadow: 0px 2px 23px 4px rgba(0,0,0,0.18);
        margin: 0.8rem 0 0 0;
    }
}

@media screen and (min-width: 280px) and (max-width: 600px) {
    .qualityCard1{
        display: inline-flex;
        background-color: rgb(247, 247, 247);
        width:94vw;
        border-radius: 10px;
        box-shadow: 0px 2px 23px 4px rgba(0,0,0,0.18);
        margin: 0.8rem 0 0 0;
    }

    .qualityText{
        margin: 2.5rem 0 2rem 0;
    }

    .qualityLine{
        display: none;
    }

    .qualityPara{
        display: none;
    }
}
/* Navbar1.css */

.nav1 {
  display: flex;
  justify-content: space-between;
  background-color: #292426;
  color: white;
  padding: 10px 40px;
}

.nav1Content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.Nav1phone,
.Nav1email,
.Nav1addrese {
  display: flex;
  align-items: center;
  gap: 10px;
}


.socialMediaNav1 {
  width: 10vw;
  display: flex;
}

.socialMediaNav1 .insta {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;

}


@media screen and (min-width: 280px) and (max-width: 600px) {
  .nav1 {
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
  }

  .nav1Content {
    width: 90vw;
    font-size: .3rem;
  }

  .socialMediaNav1 .insta {
    gap: 10px;

}
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .nav1 {
    padding: 20px;
    align-items: center;
  }

  .nav1Content {
    width: 90vw;
    font-size: .4rem;
  }

  .socialMediaNav1 {
    width: 20vw;
  }
}
.dropdown {
    float: left;
    overflow: hidden;
    /* background-color: black; */
 
}

.dropdown .dropbtn {
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
    outline: none;
    color:#ea9937;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.dropdown .dropbtn:hover {
    color: rgb(255, 255, 255);
}


.dropdown-content {
    font-size: .9rem;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ea9937;
    color: white;
}

.dropdown:hover .dropdown-content {
    display: block;
}
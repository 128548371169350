.nav2 {
    display: flex;
    overflow: hidden;
}


.nav2Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
}

.logoNav2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100px;
}

.logoNav2 img {
   width: 100%;
}

.Navrouts {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    list-style: none;
}

.routesLinks{
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
    outline: none;
    color: #ea9937;
    /* padding: 14px 16px; */
}



.Navrouts li:hover {
    color: rgb(255, 255, 255);
}

.getIntouch {
    height: 50px;
    width: 150px;
    color: white;
    font-weight: 600;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea9937;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.contantNav2 {
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.getIntouch::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #615459;
    transition: top 0.5s ease-in-out;
    z-index: -1;
}

.getIntouch:hover::before {
    top: 0;
    z-index: 1;
}

.Link {
    text-decoration: none;
}



.navContentLinks {
    display: flex;
    width: 60vw;
    justify-content: space-between;
}

.spanCrossNav2 {
    display: none;
}

@media screen and (min-width: 280px) and (max-width: 800px) {
    .nav2 {
        display: none;
    }

    .navContentLinks {
        display: none;
    }

    .nav2Content {
        padding: 0;
    }

    .logoNav2 img {
        display: none;
    }

    #navMobile {
        position: absolute;
        top: 8%;
        right: 8%;
    }


    .spanCrossNav2 {
        display: block;
    }

    .logoNav2 {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navMobilecontent {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background-color: rgb(255, 255, 255);
        color: white;
        height: 100vh;
        z-index: 3;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto 0;
    }

    .NavroutsMobile {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: white;
        justify-content: space-evenly;
        gap: 20px;
        list-style: none;
    }

    .navContentLinksMobile {
        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: space-evenly;
        color: white;
        height: 100vh;
    }

}


@media screen and (min-width: 600px) and (max-width: 800px) {
    #navMobile {
        position: absolute;
        top: 12%;
        right: 7%;
    }
}
.Home {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.HomeContent {
    background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.34)), url(../assets/home\ page.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding: 10px;
}

.HomeContent h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 6rem;
}

.HomeContent h5 {
    font-size: 1.2rem;
    font-weight: 400;
}

@media screen and (min-width: 180px) and (max-width: 290px) {
    .HomeContent h1 {
        font-size: 2.4rem;
    }

    .HomeContent h5 {
        text-align: center;
        font-size: 1rem;
        font-weight: 300;
    }
}

@media screen and (min-width: 290px) and (max-width: 1000px) {
    .HomeContent {
        padding: 10px;
    }

    .HomeContent h1 {
        font-size: 2.5rem;
    }

    .HomeContent h5 {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
    }
}


.featuresHomePage {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    height: 500px;
    width: 90%;
}

.HomeCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    height: 230px;
    width: 300px;
    background-color: #292426;
    border: 2px solid #292426;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.HomeCard h1 {
    margin: 0;
    font-size: 1.7rem;
    color: #c87e51;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.HomeCard h4 {
    text-align: center;
    font-size: 1.1rem;
    color: white;
    font-weight: 400;
    margin: 0;
}

.HomeCard:hover{
    background-color: transparent;
}




.imgLogo{
    padding: 10px;
}
.imgcard {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    /* background-image: url(../assets/design2.png); */
    opacity: .2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    padding: 30px;
    width: 150px;
    transition: opacity 0.6s ease-in-out;
}

.HomeCard:hover .imgcard {
    opacity: 1;
}


@media screen and (min-width: 270px) and (max-width: 900px) {
    .featuresHomePage {
        flex-direction: column;
        flex-wrap: wrap;
        height: auto;
        gap: 20px;
    }

    .HomeCard {
        height: 230px;
        width: 70vw;
    }

    .HomeCard h1{
        font-size: 1.2rem;

    }
    .HomeCard h5{
        font-size: 1rem;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .featuresHomePage {
        flex-direction: column;
        flex-wrap: wrap;
        height: auto;
        gap: 20px;
    }

    .HomeCard {
        height: 230px;
        width: 70vw;
    }
}

/* aboutCompany */
.aboutCompany {
    background-image: linear-gradient(#292426ec, #292426ec), url(../assets/bg\ element.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    height: 700px;
    color: #c87e51;
}

.aboutImgSection {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

}

.aboutImg {
    width: 400px;
}

.cardabout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: wheat;
    position: absolute;
    bottom: 7.4%;
    left: 0;
    background-color: #292426;
    height: 200px;
    width: 220px;
}

.cardabout h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
}

.aboutImg img {
    width: 100%;
}

.aboutContentSection {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.aboutContentSection span {
    background-color: #292426;
    border-left: 6px solid #c87e51;
    padding: 4px;
    color: white;
    width: 190px;
}

.aboutContentSection h1 {
    font-size: 3rem;
    color: c87e51;
}

.aboutContentSection h5 {
    color: wheat;
    text-align: start;
    font-weight: 400;
}

.aboutContentSection2 {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    padding: 10px;
}

.keyAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
}

.keyAbout h5 {
    display: flex;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    gap: 6px;
    font-size: 1rem;
    margin: 0;
    font-weight: 400;
}

.contentCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #292426;
    height: 180px;
    width: 170px;
    padding: 5px;
    color: #1877F2;
}

.rate {
    color: #c87e51;
    display: flex;
    flex-direction: column;
}

.rate h1,
p {
    margin: 0;
    padding: 0;
}

.client {
    color: #c87e51;
    display: flex;
    flex-direction: column;
}

.client h1,
h5 {
    text-align: center;
    margin: 0;
    padding: 0;
}

.lineabout {
    margin: 5px;
    width: 100px;
    height: 1px;
    background-color: black;
}



.getIntouchAbout {
    margin-top: 30px;
    height: 60px;
    width: 170px;
    color: white;
    border: 1px solid white;
    font-weight: 600;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292426;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.contantAbout {
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.getIntouchAbout::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c87e51;
    transition: top 0.5s ease-in-out;
    z-index: -1;
}

.getIntouchAbout:hover::before {
    top: 0;
    z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 280px) {
    .aboutCompany {
        flex-direction: column;
        height: auto;
        gap: 10px;
    }

    .lineabout {
        margin: 5px;
        width: 100px;
        height: 1px !important;
        background-color: black;
    }

    .aboutContentSection2 {
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
        gap: 30px;
    }

    .aboutImgSection {
        width: 80vw;
    }


    .aboutContentSection {
        height: auto;
    }

    .aboutContentSection h1 {
        font-size: 1rem;
    }

    .aboutContentSection p {
        font-size: .8rem;
    }
}


@media screen and (min-width: 280px) and (max-width: 600px) {
    .aboutCompany {
        flex-direction: column;
        height: auto;
        gap: 20px;
    }

    .aboutImgSection {
        width: 80vw;
    }

    .aboutContentSection {
        width: 80vw;
    }

    .cardabout {
        bottom: 1%;
        left: 0;
        height: 130px;
        width: 160px;
    }

    .cardabout h1 {
        font-size: 2rem;
    }

    .cardabout h3 {
        font-size: 1rem;
    }

    .aboutContentSection2 {
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
        gap: 30px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .aboutCompany {
        flex-direction: column;
        height: auto;
        gap: 20px;
    }

    .aboutImgSection {
        width: 80vw;
    }

    .cardabout {
        bottom: .8%;
        left: 0;
        background-color: #1877F2;
    }

    .aboutContentSection2 {
        flex-direction: row;
        height: auto;
        align-items: center;
        gap: 30px;
    }
}


/* whyYouChooseUs */

.whyYouChooseUs {
    display: flex;
    height: 700px;
    padding: 40px;
    justify-content: space-around;
}

.whyYouChooseImgSection {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


#triangle-topleft {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 2%;
    width: 0;
    height: 0;
    border-top: 100px solid #c87e51;
    border-right: 100px solid transparent;
}

#triangle-bottomright {
    z-index: -1;
    position: absolute;
    bottom: 0;
    right: 2%;
    width: 0;
    height: 0;
    border-bottom: 100px solid #c87e51;
    border-left: 100px solid transparent;
}

.WhyChooseImg {
    width: 450px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.WhyChooseImg img {
    /* border: 6px solid white; */
    width: 100%;
}


.whyYouChooseContentSetion {
    padding: 20px;
    width: 500px;
}

.whyYouChooseContentSetion span {
    padding: 5px;
    background-color: #c87e51;
    border-left: 6px solid #ffe1e1;
    color: whitesmoke;
}

.whyYouChooseContentSetion h1 {
    color: #c87e51;
    font-size: 3rem;
}

.whyYouchooseCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.whyCards {
    background-color: #c28f716f;
    /* border: 1px solid #ffffff; */
    height: 100px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.whyCards:hover,
.whyCards:hover .cardWhyImgIcon {
    background-color: #292426;
    /* border: 1px solid white; */
    color: #c87e51;
}



.whyContent {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 40vw;
}

.whyCards h4 {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    color: whitesmoke;
}

.whyCards h5 {
    color: rgb(255, 255, 255);
    margin: 0;
    text-align: start;
    font-weight: 400;
}

.whyCards:hover h4,
.whyCards:hover h5 {
   
    color: #ffffff;
}

.cardWhyImg {
    height: 8vh;
    width:5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    border: 3px solid #292426;
}

.cardWhyImg img {
    width: 100%;
}

.cardWhyImgIcon{
    scale: 3;
    color: #d2753b;
}

.cardWhyImg {
    border: 3px solid #c87e51;
}


@media screen and (min-width: 280px) and (max-width: 600px) {
    .whyYouChooseUs {
        height: auto;
        padding: 10px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .whyYouChooseImgSection {
        width: 85vw;
    }

    .WhyChooseImg {
        width: 85vw;
        height: auto;
    }

    .whyYouChooseContentSetion {
        width: 85vw;
    }
    .whyYouChooseContentSetion h1{
        font-size: 2rem;
    }

    #triangle-topleft {
        display: none;
    }

    #triangle-bottomright {
        display: none;
    }

    .whyCards {
        height: auto;
    }

    .whyCards h4 {
        font-size: 1.3rem;
    }

    .cardWhyImg {
        height: auto;
    }
    .whyContent {
        width: 90vw;
    }

}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .whyYouChooseUs {
        height: auto;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }

    .whyYouChooseImgSection {
        width: 85vw;
    }

    .WhyChooseImg {
        width: 85vw;
        height: auto;
    }

    #triangle-topleft {
        display: none;
    }

    #triangle-bottomright {
        display: none;
    }
    .whyContent {
        width: 90vw;
    }
}

/* reviews */
.reviews {
    display: flex;
    padding: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #6d6d6d; */
    color: white;
}

.reviewCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 900px;
}

.reviewLeft {
    height: 260px;
    width: 550px;
    /* background-color: rgb(0, 0, 0); */
    border-radius: 1rem;
    position: relative;
    color: #c87e51;

}

.cooomaReview {
    filter: invert(100%);
    width: 90px;
    height: 100px;
    position: absolute;
    top: 7%;
    left: 7%;
    opacity: 1;
}

.cooomaReview img {
    width: 100%;
}

.contentreview {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contentreview h1 {
    text-align: center;
    color: white;
    width: 200px;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.contentreview .pTag {
    width: 400px;
    padding: 10px;
    margin: 0;
    font-size: .9rem;
}

.userReviewIcon {
    scale: 1.4;
    margin-right: 10px;
}


.swiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #292426;
    margin: 0 auto;
    display: block;

}

.swiper-button-prev{
    color: #d2753b !important;
}

.swiper-button-next{
    color: #d2753b !important;
}

.swiper-slide-active {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

@media screen and (min-width: 280px) and (max-width: 600px) {
    .reviewCard {
        width: 100vw;
    }

    .reviewLeft {
        width: 75vw;
        padding: 10px;
        height: auto;
        max-width: 90%;
        border: radius 1rem;
    }

    .contentreview .pTag {
        width: 100%;
        padding: 10px;
        font-size: .7rem;
    }


}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .reviewCard {
        width: 85vw;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.headSpan2{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.headSpan2 span{
    background-color: #8c5c3f;
    color: white;
    padding: 5px;
    border-left: 5px solid rgb(255, 77, 0);
}
.aboutPages2{
    display: flex;
    flex-direction: column;
    padding: 50px 150px;
   justify-content: center;
    /* background-image:linear-gradient(rgba(255, 255, 255, 0.648),rgba(193, 234, 252, 0.699)), url(../assets/bgele3.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    
}


.firstSection2{
    display: flex;
    gap: 20px;
}

.contentFirst2{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 500px;
    padding: 0 10px;
    color: white;
}
.contentFirst2 p{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.11rem;
    font-style: normal;
}
.FirstImgsection2{
   width: 500px;
   height: auto;
   object-fit: cover;
}


.FirstImgsection2 img{
    object-fit: cover;
    width: 500px;
    height: 70vh;
}

.secondSection2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
}

.secondImg2{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 1000px;
    height: 500px;
    overflow: hidden;
}

.secondImg2 img{
    width: 100%;
}

.ContentSecond2{
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.11rem;
    padding: 20px;
    font-style: normal;
    color: #b95415;
    
}

.ContentSecond2 h4{
    color: #ffffff;
}


@media screen and (min-width: 270px) and (max-width: 1260px) {
    .aboutPages2 {
        height: auto;
        padding: 15px;

    }

    .contentFirst2 {
        text-align: center;
        width: 90vw;
        height: auto;
    }

    .firstSection2 {
        flex-direction: column;
        align-items: center;
    }


    .FirstImgsection2 {
        height: auto;
        width: auto;
    }

    .FirstImgsection2 img {
        width: 90vw;
    }

    .secondSection2{
        padding: 0;
    }
    .secondImg2{
        height: auto;
        padding: 20px 0;
        width: auto;
    }

    .secondImg2 img {
        width: 70vw;
    }
}
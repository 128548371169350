.careerPage{
    display: flex;justify-content: center;
    flex-direction: column;
}
.contnetCareerPage{
  padding: 10px;
}

.CareerPageIMg{
    background-image:linear-gradient(rgba(0, 0, 0, 0.685),rgba(0, 0, 0, 0.507)) ,url(../assets/career\ \(2\).jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

.headSpanCareerPage {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.headSpanCareerPage span {
    padding: 10px;
    background-color: #8c5c3f;
    color: white;
    border-left: 6px solid rgb(255, 123, 0);
}

.contnetCareerPage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

}
.collapsible {
    text-align: start;
    justify-content: center;
    background-color: #302a2d;
    width: 90vw;
    color: #9a5b34;
    cursor: pointer; 
  }
  .collapsible p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 30px 20px;
    font-size: 1.2rem;
    font-weight: 500;
  }
  

  .content {
    width: 89.7vw;
    display: none;
    overflow: hidden;
    background-color: black;
    border: 2px solid #8c5c3f;
   
  }
  .content p{
    padding: 0;
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 300;
  }

  .boxContent{
    padding: 120px;
    flex-direction: column;
    display: flex;
    justify-content: start;
    color: #ffffff;
    
  }

  @media screen and (min-width: 280px) and (max-width: 800px) {
    .contnetCareerPage{
      padding: 10px;
    }
    .CareerPageIMg{
      height: 400px;
  }

  .content {
    width: 88.5vw;
  }
    .collapsible p{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-size: 1rem;
      font-weight: 500;
    }
    .boxContent{
      padding: 10px;
      flex-direction: column;
      display: flex;
      justify-content: start;
      color: #9a5b34;
      
    }
  }

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    .contnetCareerPage{
      padding: 10px;
    }
  }
